<template>
  <v-container style="background-color:white" fluid v-if="show" id="download">
        <v-row>
            <v-col>
                <v-simple-table>
                <thead>
                    <tr>
                        <td class="primary--text text-center">#</td>
                        <td class="primary--text text-center">ناوی کاڵا</td>
                        <td class="primary--text text-center">کۆدی کاڵا</td>
                        <td class="primary--text text-center">بڕ بە یەکە</td>
                        <td class="primary--text text-center">بڕ بە دانە</td>
                        <td class="primary--text text-center">بڕی ماوە بە پارە</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                        <td class=" text-center">{{i+1}}</td>
                        <td class=" text-center">{{item.item_name}}</td>
                        <td class=" text-center">{{item.item_code}}</td>
                        <td class=" text-center">{{item.qty_unit}}</td>
                        <td class=" text-center">{{item.qty_item}}</td>
                        <td class=" text-center">{{'$' + item.total_amount}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            </v-col>
        </v-row>
        <v-divider class="mt-5 black--text"></v-divider>
        
        <hr>
  </v-container>
</template>

<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
export default {
    data() {
        return {
            items: [],
            
        }
    },
    async created() {
            const {data} = await this.axios.get('/report/inventory/')
            this.items = await data 
    },
    async updated(){
        
                 var canvas = document.getElementById('download') 
              html2canvas(canvas, {scale:4}).then(async (canvas)=>{
                  var imgData = canvas.toDataURL('image/png')
                  var doc = jsPDF(
                        {
                          orientation: 'p',
                            unit: 'mm',
                            format: 'a4',
                        })
                  await doc.addImage(imgData, 'PNG', 0, 0,210,90)
                  await doc.save(' جەردی  -'+new Date().toISOString().split("T")[0])
                  setTimeout(()=> window.close())
              })    
    },
    computed:{
        show(){
            return this.items.length> 0
        }
    }
   
}
</script>

<style scoped>
    tr td{
        border: 1px solid black;
        border-radius: 3%;
    }
    tr{
        
    }
   
</style>